<template>
<div id="Footer">
  <div class="footBox">
    <div class="footTop">
      <div class="conListImg">
        <img :src="baseUrl+'imges/footer/footerLogo.svg'" alt="" class="logo">
      </div>
      <div class="conList">
        <router-link to="/solution" class="footLink footLinkTitle">解决方案</router-link>
        <router-link :to="{name: 'Solutionlist', params: {type:'wisdomChemical',name:'智慧化工管廊'}}" class="footLink">
          <div @click="checkPageType('智慧化工管廊')">
            智慧化工管网
          </div>
        </router-link>
        <router-link :to="{name: 'Solutionlist', params: {type:'wisdomGas',name:'智慧燃气管网'}}" class="footLink">
          <div @click="checkPageType('智慧燃气管网')">
            智慧燃气管网
          </div>
        </router-link>
        <router-link :to="{name: 'Solutionlist', params: {type:'wisdomCity',name:'智慧城市地下管网'}}" class="footLink">
          <div @click="checkPageType('智慧城市地下管网')">
            智慧地下管网
          </div>
        </router-link>
      </div>
      <div class="conList">
        <router-link to="/technology" class="footLink footLinkTitle">硬件产品</router-link>
        <router-link :to="{name: 'Productlist', params: {type:'5G01',name:'RA52-CN工业(5G)路由器'}}" class="footLink">
          <div @click="checkPageType('RA52-CN工业(5G)路由器')">
            RA52-CN (5G)
          </div>
        </router-link>
        <router-link :to="{name: 'Productlist', params: {type:'Z0115',name:'DTU-Z0115'}}" class="footLink">
          <div @click="checkPageType('DTU-Z0115')">
            DTU-Z0115
          </div>
        </router-link>
        <router-link :to="{name: 'Productlist', params: {type:'CPE',name:'5G CPE'}}" class="footLink">
          <div @click="checkPageType('5G CPE')">
            5G CPE
          </div>
        </router-link>
      </div>
      <div class="conList">
        <router-link to="/about" class="footLink footLinkTitle">关于我们</router-link>
        <router-link :to="{name: 'About', params: {type:'intro',name:'公司简介',val:'true'}}" class="footLink">
          <div @click="checkPageType">
            公司简介
          </div>
        </router-link>
<!--        <router-link :to="{path: '/honorvideo'}" class="footLink">-->
<!--          <div @click="checkPageType">-->
<!--            公司视频-->
<!--          </div>-->
<!--        </router-link>-->
        <router-link :to="{path: '/certificate'}" class="footLink">
          <div @click="checkPageType">
            企业荣誉
          </div>
        </router-link>
      </div>
      <div class="MobileConList">
        <div>
          <el-collapse v-model="activeName" accordion>
            <el-collapse-item title="解决方案" name="11">
              <router-link :to="{path: '/solution'}">
                <div @click="toBack('/solution')">
                  所有解决方案
                </div>
              </router-link>
              <router-link :to="{name: 'Solutionlist', params: {type:'wisdomChemical',name:'智慧化工管廊'}}">
                <div @click="checkPageType('智慧化工管廊')">
                  智慧化工管网
                </div>
              </router-link>
              <router-link :to="{name: 'Solutionlist', params: {type:'wisdomGas',name:'智慧燃气管网'}}">
                <div @click="checkPageType('智慧燃气管网')">
                  智慧燃气管网
                </div>
              </router-link>
              <router-link :to="{name: 'Solutionlist', params: {type:'wisdomCity',name:'智慧城市地下管网'}}">
                <div @click="checkPageType('智慧城市地下管网')">
                  智慧地下管网
                </div>
              </router-link>
            </el-collapse-item>
            <el-collapse-item title="产品与服务" name="12">
              <router-link :to="{path: '/technology'}">
                <div @click="$emit('goTop'); BoxStatus = false; $store.commit('chengeScrollIntoView', 0)">
                  所有产品
                </div>
              </router-link>
              <router-link :to="{path: '/technology'}">
                <div @click="technologyScroll('#product_1')">
                  硬件产品
                </div>
              </router-link>
              <router-link :to="{path: '/technology'}">
                <div @click="technologyScroll('#robot_1')">
                  巡检机器人
                </div>
              </router-link>
            </el-collapse-item>
            <el-collapse-item title="关于我们" name="13">
              <router-link :to="{name: 'About', params: {type:'intro',name:'公司简介',val:'true'}}">
                <div @click="toBack('/about')">
                  公司简介
                </div>
              </router-link>
              <router-link :to="{path: '/certificate'}">
                <div @click="toBack('/certificate')">
                  荣誉资质
                </div>
              </router-link>
<!--              <router-link :to="{path: '/honorvideo'}">-->
<!--                <div @click="toBack('/honorvideo')">-->
<!--                  公司视频-->
<!--                </div>-->
<!--              </router-link>-->
            </el-collapse-item>
          </el-collapse>

        </div>
      </div>
      <div class="conListText">
        <p class="span">总部地址：成都市高新区环球中心W6区</p>
        <p>电话：028-84175896</p>
        <img :src="baseUrl+'footer/erweima.jpg'" alt="" class="erweima">
      </div>
    </div>
    <div class="footBom">
      <span>版权所有@2021成都蓉视通科技有限公司</span>
      <a href="https://beian.miit.gov.cn" target="_blank" class="footLink">ICP备案号：蜀ICP备17022181号-1</a>
<!--      <a href="https://beian.miit.gov.cn" target="_blank" class="footLink">ICP备案号：蜀ICP备17022181号-2</a>-->
      <span>网站建设：成都蓉视通科技有限公司</span>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "Footer",
  data(){
    return{
      activeName: '10'
    }
  },
  methods:{
    checkPageType(e){
      this.$emit('thisPageType', e)
      // 回到顶部
      document.documentElement.scrollTop = 0
    },
    //返回当前页面或跳转页面，并回到顶部
    toBack(e){
      if(this.$route.path === e){
        document.documentElement.scrollTop = 0
      } else {
        this.$router.push(e)
      }
    },
    // 产品与服务页面的 锚点定位
    technologyScroll(id){
      if(this.$route.path !== '/technology'){
        if(id === '#product_1'){
          this.$store.commit('chengeScrollIntoView', 1)
        } else if(id === '#robot_1'){
          this.$store.commit('chengeScrollIntoView', 2)
        }
        this.$router.push('/technology')
      } else {
        this.$store.commit('chengeScrollIntoView', 0)
        this.$emit('goAnchor', id)
        this.BoxStatus = false
      }
    },
  }
}
</script>

<style lang="less" scoped>
#Footer{
  //大盒子：背景颜色和整体大小
  .footBox{
    width: 100%;
    //height: 297px;
    height: 31.7vh;
    background: #101E2C;
    z-index: 10;
    .MobileConList{display: none}
    .conList{display: block}
    //居中次级盒子，定义flex模式
    .footTop{
      width: 1200px;
      //height: 244px;
      height: 25.708vh;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid rgba(242, 243, 254, .2);
        //第一个flex子级盒子，控制公司logo位置
        .conListImg{
          margin-top: 43px;
          text-align: left;
          width: 317px;
          //公司logo，控制大小
          .logo{
            width: 147px;
            height: 58px;
          }
        }
        //第二、三、四导航盒子，控制纵轴flex导航标签
        .conList{
          margin-top: 43px;
          width: 192px;
          text-align: left;
          display: flex;
          flex-direction: column;
          justify-content: left;
          //整体标签样式
          .footLink{
            font-family: Regular;
            font-size: 12px;
            font-weight: normal;
            letter-spacing: 0em;
            color: #999999;
            margin-bottom: 13px;
          }
          //标签头样式
          .footLinkTitle{
            font-family: Medium;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0em;
            color: #FFFFFF;
            margin-bottom: 29px;
          }
        }
        //第五个flex盒子样式
        .conListText{
          margin-top: 43px;
          text-align: right;
          width: 416px;
          //设置文本样式
          p{
            font-family: Regular;
            font-size: 12px;
            font-weight: normal;
            line-height: 20px;
            letter-spacing: 0em;
            color: #FFFFFF;
          }
          //设置二维码样式
          .erweima{
            width: 70px;
            height: 70px;
            margin-top: 24px;
          }
        }
      }
    }
    //底部次级盒子，设置文本样式
    .footBom{
      font-family: Regular;
      font-size: 12px;
      font-weight: normal;
      letter-spacing: 0em;
      color: #999999;
      text-align: left;
      width: 1200px;
      height: 52px;
      line-height: 52px;
      margin: 0 auto;
      //ICP链接样式
      .footLink{
        color: #999999;
        margin: 0 10px;
      }
    }
}

@media screen and (min-width: 360px) and (max-width: 750px){
  html{
    font-size: 150px / 10;
  }
  #Footer{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    //height: 598px;
    background: #101E2C;
    .footBox{
      width: 90%;
      height: 100%;
      margin: 0 auto;
      padding-top: 55px;
      z-index: 10;
      .footTop{
        display: flex;
        flex-direction: column;
        justify-content: left;
        width: 100%;
        height: 100%;
        //height: 473px;
        border: 0;
        .conListImg{
          width: 147px;
          height: 58px;
          .logo{
            width: 147px;
            height: 58px;
          }
        }
        .conList{display: none}
        .MobileConList{
          display: block;
          margin-top: 44.5px;
          width: 100%;
        }
        .MobileConList/deep/.el-collapse {
          border-top: 1px solid rgba(216, 216, 216, 0.2);
          border-bottom: 1px solid rgba(216, 216, 216, 0.2);
        }
        .MobileConList/deep/.el-collapse-item__header{
          height: 55px;
          font-family: Medium;
          font-size: 15px;
          font-weight: 500;
          letter-spacing: 0em;
          color: #FFFFFF;
          background: #101E2C;
          border-bottom: 1px solid rgba(216, 216, 216, 0.2);
        }
        .MobileConList/deep/.el-collapse-item__wrap {
          background-color: #101E2C;
          text-align: left;
          padding-left: 15px;
          overflow: hidden;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          border-bottom: 1px solid rgba(216, 216, 216, 0.2);
        }
        .MobileConList/deep/.el-collapse-item__content {
          font-size: 13px;
          padding-bottom: 0;
          a{
            height: 50px;
            line-height: 50px;
            color: #FFFFFF;
            font-family: Medium;
          }
        }
        .conListText{
          width: 100%;
          height: 130px;
          text-align: left;
          margin-top: 27.5px;
          p{
            font-family: Regular;
            font-size: 12px;
            font-weight: normal;
            line-height: 20px;
            letter-spacing: 0em;
            color: #FFFFFF;
          }
          .erweima{
            width: 70px;
            height: 70px;
            margin-top: 18px;
          }
        }
      }
    }
    .footBom{
      width: 100%;
      height: 72px;
      font-family: Regular;
      font-size: 10px;
      line-height: 20px;
      margin-top: 42px;
      font-weight: normal;
      letter-spacing: 0em;
      color: #999999;
    }
  }
}

</style>
