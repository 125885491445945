<template>
  <div id="app">
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
    <!--    <router-view/>-->

  </div>
</template>
<script>

export default {
  name: 'App',
  components: {},
  mounted() {

  },
  watch: {
    '$route': function (to, from) {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    }
  }
  }
</script>
<style lang="less">
* {
  margin: 0;
  padding: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
a{
  text-decoration: none;
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: rgb(81, 105, 220) !important;
}

.fade-enter {
  opacity:0;
}

.fade-leave {
  opacity:1;
}
.el-breadcrumb{
  font-size: 14px !important;
}
.el-dialog__body{
  padding:10px !important;
}
.el-dialog__header{
  margin-bottom: -25px;
}
.el-submenu__title{
  height: 36px !important;
  line-height: 36px !important;
  padding: 0 !important;
  margin: 10px auto;
  font-size: 16px !important;
  //&:hover{
    //background: url("./assets/img/solution/solutionNavBG.png") !important;
    //background-size: 100%;
    background: #4673EA !important;
    color: #FFFFFF;
    font-size: 16px;
    font-family: Medium;
  //}
}
.el-menu-item{
  &:hover{
    color: rgb(81, 105, 220);
  }
}
.el-menu-item-group__title{
  padding: 0 !important;
}
</style>
