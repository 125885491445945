import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/productList',
    name: 'Productlist',
    component: () => import('../views/Productlist.vue')
  },
  {
    path: '/solution',
    name: 'Solution',
    component: () => import('../views/Solution.vue')
  },
  {
    path: '/solutionList',
    name: 'Solutionlist',
    component: () => import('../views/Solutionlist.vue')
  },
  {
    path: '/news',
    name: 'News',
    component: () => import('../views/News.vue')
  },
  {
    path: '/newsList',
    name: 'Newslist',
    component: () => import('../views/Newslist.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/certificate',
    name: 'Certificate',
    component: () => import('../views/Certificate.vue')
  },
  {
    path: '/honorvideo',
    name: 'Honorvideo',
    component: () => import('../views/Honorvideo.vue')
  },
  {
    path: '/technology',
    name: 'Technology',
    component: () => import('../views/Technology.vue')
  },
  {
    path: '/technologylist',
    name: 'Technologylist',
    component: () => import('../views/Technologylist.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  linkActiveClass: 'active',
  routes
})

export default router
