import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import VueFullPage from 'vue-fullpage.js'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Video from 'video.js'
import 'video.js/dist/video-js.css'
import './assets/font/font.css'
import AOS from 'aos'
import 'aos/dist/aos.css'
import Router from 'vue-router'

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
// 注：官方vue-router@3.0及以上新版本路由默认回调返回的都是promise，原先就版本的路由回调将废弃！！！！


Vue.config.productionTip = false
// Vue.prototype.baseUrl = "https://cdn.jsdelivr.net/gh/NoGlide/-@master/" //CDN
Vue.prototype.baseUrl = "http://www.cd-rst.top:18000/static/" //CDN
Vue.prototype.$video = Video  //video
Vue.use(VueFullPage);
Vue.use(ElementUI);
Vue.use(AOS);
// AOS.init()
AOS.init({
  offset:120,//px，提前或延迟触发
  duration:400,//ms，过渡时间，50-3000
  delay:0,//s，延迟执行
  anchor:null,//body，指定元素触发，Element
  once:false//是否仅触发一次
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
