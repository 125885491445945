<template>
  <div id="Header">
    <div class="Header is-active" v-if="scrollTop>300&&$route.path==='/about'">
      <div class="headBox">
        <div class="logo" @click="toBack('/')">
          <p>关于我们</p>
        </div>
        <ul class="menuList">
          <li :class="status===1?'is_scoll' :''">
            <span @click="$emit('goAnchor', '#anchor_1')">
              公司介绍
            </span>
          </li>
          <li :class="status===2?'is_scoll' :''">
            <span @click="$emit('goAnchor', '#anchor_2')">
              使命与愿景
            </span>
          </li>
          <li :class="status===3?'is_scoll' :''">
            <span @click="$emit('goAnchor', '#anchor_3')">
              联系我们
            </span>
          </li>
        </ul>
      </div>
    </div>
    <div class="Header" :class="scrollTop>300||$route.path!=='/'?'is-active':''" v-else>
      <div class="headBox">
        <div class="logo" @click="toBack('/')">
          <img :src="baseUrl+'header/rstlogo.svg'" alt="">
        </div>
        <div class="logo_1">
          <img :src="baseUrl+'header/rstlogo_1.svg'" alt="">
        </div>
        <ul class="menuList">
          <li>
            <router-link :to="{path: '/'}">
              首页
            </router-link>
          </li>
          <li class="menuSolution">
            <router-link :to="{path: '/solution'}">
              解决方案
            </router-link>
            <div class="solutionPop">
              <router-link :to="{name: 'Solutionlist', params: {type:'wisdomChemical',name:'智慧化工管廊'}}">
                <div @click="checkPageType('智慧化工管廊')">
                  智慧化工管网
                </div>
              </router-link>
              <router-link :to="{name: 'Solutionlist', params: {type:'wisdomGas',name:'智慧燃气管网'}}">
                <div @click="checkPageType('智慧燃气管网')">
                  智慧燃气管网
                </div>
              </router-link>
              <router-link :to="{name: 'Solutionlist', params: {type:'wisdomCity',name:'智慧城市地下管网'}}">
                <div @click="checkPageType('智慧城市地下管网')">
                  智慧地下管网
                </div>
              </router-link>
            </div>
          </li>
          <li class="menuProduct">
            <router-link :to="{path: '/technology'}">
              <div @click="$emit('goTop'); $store.commit('chengeScrollIntoView', 0)">
                产品与服务
              </div>
            </router-link>
            <div class="productPop">
              <router-link :to="{path: '/technology'}" class="productPopChild">
                <div @click.stop="technologyScroll('#product_1')">
                  硬件产品
                </div>
                <div class="productlistPop">
                  <router-link :to="{name: 'Productlist', params: {type:'5G01',name:'RA52-CN工业(5G)路由器'}}">
                    <div @click="checkPageType('RA52-CN工业(5G)路由器')">
                      RA52-CN工业(5G)路由器
                    </div>
                  </router-link>
                  <router-link :to="{name: 'Productlist', params: {type:'KT100',name:'工业4G路由器'}}">
                    <div @click="checkPageType('工业4G路由器')">
                      工业4G路由器
                    </div>
                  </router-link>
                  <router-link :to="{name: 'Productlist', params: {type:'Z0115',name:'DTU-Z0115'}}">
                    <div @click="checkPageType('DTU-Z0115')">
                      DTU-Z0115
                    </div>
                  </router-link>
                  <router-link :to="{name: 'Productlist', params: {type:'CPE',name:'5G CPE'}}">
                    <div @click="checkPageType('5G CPE')">
                      5G CPE
                    </div>
                  </router-link>
                  <router-link :to="{name: 'Productlist', params: {type:'light',name:'便捷式应急灯'}}">
                    <div @click="checkPageType('便捷式应急灯')">
                      便捷式应急灯
                    </div>
                  </router-link>
                  <router-link :to="{name: 'Productlist', params: {type:'5G02',name:'双模5G路由器'}}">
                    <div @click="checkPageType('双模5G路由器')">
                      双模5G路由器
                    </div>
                  </router-link>
                </div>
              </router-link>
<!--              <router-link :to="{name: 'Technologylist', params: {type:'personnelLocationSystem',name:'轨道式巡检机器人'}}">-->
              <router-link :to="{path: '/technology'}">
                <div @click.stop="technologyScroll('#robot_1')">
                  巡检机器人
                </div>
              </router-link>

            </div>
          </li>
          <li>
            <router-link :to="{path: '/news'}">
              新闻动态
            </router-link>
          </li>
          <li class="menuAbout">
            <router-link :to="{path: '/about'}" class="about">
              关于我们
            </router-link>
            <div class="aboutPop">
              <router-link :to="{name: 'About', params: {type:'intro',name:'公司简介',val:'true'}}">公司简介</router-link>
              <router-link :to="{path: '/certificate'}">荣誉资质</router-link>
<!--              <router-link :to="{path: '/honorvideo'}">公司视频</router-link>-->
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="mobileHeader">
      <div>
        <img :src="baseUrl+'imges/header/rstMobileL.svg'" alt="" class="leftImg" @click="toBack('/')">
        <img :src="baseUrl+'imges/header/rstMobileR.svg'" alt="" class="rightImg" @click="BoxStatus=!BoxStatus">
      </div>
      <div class="mobileHeader_Box" v-if="BoxStatus">
        <div class="fatherDiv">
          <el-collapse v-model="activeName" accordion>
            <el-collapse-item title="首页"
                              name="0"
                              disabled
                              @click.native="toBack('/')"
                              class="HomeContent"
            >
            </el-collapse-item>
            <el-collapse-item title="解决方案" name="1">
              <router-link :to="{path: '/solution'}">
                <div @click="toBack('/solution')">
                  所有解决方案
                </div>
              </router-link>
              <router-link :to="{name: 'Solutionlist', params: {type:'wisdomChemical',name:'智慧化工管廊'}}">
                <div @click="checkPageType('智慧化工管廊')">
                  智慧化工管网
                </div>
              </router-link>
              <router-link :to="{name: 'Solutionlist', params: {type:'wisdomGas',name:'智慧燃气管网'}}">
                <div @click="checkPageType('智慧燃气管网')">
                  智慧燃气管网
                </div>
              </router-link>
              <router-link :to="{name: 'Solutionlist', params: {type:'wisdomCity',name:'智慧城市地下管网'}}">
                <div @click="checkPageType('智慧城市地下管网')">
                  智慧地下管网
                </div>
              </router-link>
            </el-collapse-item>
            <el-collapse-item title="产品与服务" name="2">
              <router-link :to="{path: '/technology'}">
                <div @click="$emit('goTop'); BoxStatus = false; $store.commit('chengeScrollIntoView', 0)">
                  所有产品
                </div>
              </router-link>
              <router-link :to="{path: '/technology'}">
                <div @click.stop="technologyScroll('#product_1')">
                  硬件产品
                </div>
              </router-link>
              <router-link :to="{path: '/technology'}">
                <div @click.stop="technologyScroll('#robot_1')">
                  巡检机器人
                </div>
              </router-link>
            </el-collapse-item>
            <el-collapse-item title="新闻动态"
                              name="3"
                              disabled
                              @click.native="toBack('/news')"
                              class="NewsContent"
            >
            </el-collapse-item>
            <el-collapse-item title="关于我们" name="4">
              <router-link :to="{name: 'About', params: {type:'intro',name:'公司简介',val:'true'}}">
                <div @click="toBack('/about')">
                  公司简介
                </div>
              </router-link>
              <router-link :to="{path: '/certificate'}">
                <div @click="toBack('/certificate')">
                  荣誉资质
                </div>
              </router-link>
<!--              <router-link :to="{path: '/honorvideo'}">-->
<!--                <div @click="toBack('/honorvideo')">-->
<!--                  公司视频-->
<!--                </div>-->
<!--              </router-link>-->
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  data(){
    return{
      scrollTop: 0,       //滚动条高度
      screenWidth: document.body.clientWidth,     // 当前屏幕宽
      Pop: '',
      BoxStatus: false, //控制APP端顶栏盒子
      activeName: '',
    }
  },
  props:{
    nowNav: '',
    status: Number,
  },

  mounted() {
    //监听滚动高度
    window.addEventListener("scroll", this.handleScroll, true);
  },

  methods:{
    //解决方案详情页面跳转
    checkPageType(e){
      this.$emit('thisPageType', e)
      // 回到顶部
      document.documentElement.scrollTop = 0
      this.BoxStatus = false
    },
    //监听滚动高度的方法
    handleScroll(e) {
      this.scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;
    },
    //返回当前页面或跳转页面，并回到顶部
    toBack(e){
      if(this.$route.path === e){
        document.documentElement.scrollTop = 0
      } else {
        this.$router.push(e)
      }
      this.BoxStatus = false
    },
    // 产品与服务页面的 锚点定位
    technologyScroll(id){
      if(this.$route.path !== '/technology') {
        if (id === '#product_1') {
          this.$store.commit('chengeScrollIntoView', 1)
          this.$router.push('/technology')
          this.BoxStatus = false
        } else if (id === '#robot_1') {
          this.$store.commit('chengeScrollIntoView', 2)
          this.$router.push('/technology')
          this.BoxStatus = false
        } else {
          this.$store.commit('chengeScrollIntoView', 0)
          this.$emit('goTop')
          this.$router.push('/technology')
          this.BoxStatus = false
        }
      } else {
        if(id){
          this.$emit('goAnchor', id)
        }
        this.BoxStatus = false
      }
    },

    //点击解决方案导航，顶栏消失 暂时未用
    openSolution(){
      this.isSolution = true
      document.documentElement.scrollTop = 0
      this.BoxStatus = false
    },
    //点击解决方案导航，顶栏消失 暂时未用
    openTechnology(){
      this.isTechnology = true
      document.documentElement.scrollTop = 0
      this.BoxStatus = false
    },
    //暂时未用
    openMenu(){
      this.isActive=!this.isActive;
      this.drawerRightNav=!this.drawerRightNav;
      this.BoxStatus = false
    },
    //暂时未用
    displayPop(e){
      this.Pop = e
    },
  }
}
</script>

<style lang="less" scoped>
.Header{
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100vw;
  min-width: 1225px;
  height: 70px;
  background: rgba(0,0,0, 0.6);
  z-index: 100;
  //顶栏居中父级盒子
  .headBox{
    margin: 0 auto;
    width: 84%;
    display: flex;
    justify-content: space-between;
    //蓉视通logo
    .logo{
      width: 130px;
      height: 32px;
      margin-top: 21px;
      //margin-right: -60px;
    }
    .logo_1{
      margin-top: 30px;
      margin-left: 30px;
      height: 20px;
    }
    //ul导航栏
    .menuList{
      margin: 0 auto;
      width: 100%;
      display: flex;
      justify-content: right;
      .menuSolution{
        position: relative;
        &:hover .solutionPop{
          visibility: visible !important;
        }
        //解决方案弹框盒子
        .solutionPop{
          visibility: hidden;
          position: absolute;
          left: 0;
          //left: 816px;
          top: 70px;
          width: 150px;
          height: 174px;
          display: flex;
          flex-direction: column;
          justify-content: left;
          background: #FFFFFF;
          box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.05),
          0px 4px 5px 0px rgba(0, 0, 0, 0.08),
          0px 2px 4px -1px rgba(0, 0, 0, 0.12);
          a{
            width: 150px;
            height: 58px;
            text-align: center;
            line-height: 58px;
            font-family: Regular;
            font-size: 14px;
            letter-spacing: 0em;
            color: #3D3D3D;
            &:hover{
              color: #0564AF;
            }
          }
        }
      }
      //产品弹框盒子
      .menuProduct{
        position: relative;
        &:hover .productPop{
          visibility: visible !important;
        }
        .productPop{
          visibility: hidden;
          position: absolute;
          left: 0;
          top: 70px;
          width: 150px;
          height: 125px;
          display: flex;
          flex-direction: column;
          justify-content: left;
          background: #FFFFFF;
          box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.05),
          0px 4px 5px 0px rgba(0, 0, 0, 0.08),
          0px 2px 4px -1px rgba(0, 0, 0, 0.12);
          a{
            width: 150px;
            height: 62.5px;
            text-align: center;
            line-height: 62.5px;
            font-family: Regular;
            font-size: 14px;
            color: #3D3D3D;
            >a:hover{
              color: #0564AF;
              border-right: 2px solid #0564AF;
            }
          }
          .productPopChild{
            //&:hover .productlistPop{
            //  visibility: visible !important
            //}
            .productlistPop{
              visibility: hidden;
              position: absolute;
              left: 150px;
              top: 0;
              width: 346px;
              height: 290px;
              display: flex;
              flex-direction: column;
              justify-content: space-around;
              background: #FFFFFF;
              a{
                width: 309px;
                height: 40px;
                text-align: left;
                line-height: 40px;
                margin-left: 37px;
                font-family: Regular;
                font-size: 14px;
                color: #3D3D3D;
                &:hover{
                  color: #0564AF;
                }
              }
            }
          }
        }
      }
      //关于我们弹框盒子
      .menuAbout{
        position: relative;
        &:hover .aboutPop{
          visibility: visible !important;
        }
        .aboutPop{
          visibility: hidden;
          position: absolute;
          left: 0;
          top: 70px;
          width: 150px;
          //height: 174px;
          display: flex;
          flex-direction: column;
          justify-content: left;
          background: #FFFFFF;
          box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.05),
          0px 4px 5px 0px rgba(0, 0, 0, 0.08),
          0px 2px 4px -1px rgba(0, 0, 0, 0.12);
          a{
            width: 150px;
            height: 58px;
            text-align: center;
            line-height: 58px;
            font-family: Regular;
            font-size: 14px;
            letter-spacing: 0em;
            color: #3D3D3D;
            &:hover{
              color: #0564AF;
            }
          }
        }
      }
      //所有导航li标签的样式
      li{
        width: 146px;
        height: 66px;
        z-index: 2;
        //router-link文字样式和布局
        a{
          display: inline-block;
          line-height: 66px;
          font-size: 16px;
          font-family: Regular;
          font-weight: 500;
          color: #FFFFFF;
          letter-spacing: 0em;
          text-decoration: none;
        }
        span{
          position: relative;
          cursor: pointer;
          display: inline-block;
          line-height: 66px;
          font-size: 16px;
          font-family: Medium;
          font-weight: 500;
          letter-spacing: 0em;
          text-decoration: none;
          //设置css动画
          &:before{
            content: "";
            position: absolute;
            left: 0;
            bottom: -4px;
            height: 4px;
            width: 100%;
            background: #0564AF;
            transform: scale(0);
            transition: all .5s;
          }
        }
      }
      .is_scoll{
        span{
          font-family: Medium;
          //必须需要相对定位，才能让css动画在此标签下
          position: relative;
          color: #0564AF;
          //触发css动画
          &:before {

            transform: scale(1);
          }
        }
      }
    }
  }



}
//鼠标经过导航栏时
.Header:hover{
  transition:0.5s;
  background: #FFFFFF;
  box-shadow: 0px 6px 10px 0px rgba(3, 8, 12, 0.15);
  .headBox{
    .logo{
      width: 130px;
      height: 32px;
      content: url("http://www.cd-rst.top:18000/static/header/rstlogo1.svg");
    }
    .logo_1{
      content: url("http://www.cd-rst.top:18000/static/header/rstlogo_2.svg");
    }
    .menuList{
      li{
        >a{
          font-family: Medium;
          color: #999999;
          //必须需要相对定位，才能让css动画在此标签下
          position: relative;
        }
        //鼠标经过触发css动画
        >a:hover:before {
          transform: scale(1);
        }
        //鼠标经过触发css动画
        >a:before {
          content: "";
          position: absolute;
          left: 0;
          bottom: -4px;
          height: 4px;
          width: 100%;
          background: #0564AF;
          transform: scale(0);
          transition: all .5s;
        }
        >span{
          font-family: Medium;
          //必须需要相对定位，才能让css动画在此标签下
          position: relative;
          //触发css动画
          &:hover:before {
            transform: scale(1);
            color: #0564AF;
          }
          //设置css动画
          &:before{
            content: "";
            position: absolute;
            left: 0;
            bottom: -4px;
            height: 4px;
            width: 100%;
            background: #0564AF;
            transform: scale(0);
            transition: all .5s;
          }
        }
      }
    }
  }
}
//鼠标点击导航栏后
.is-active{
  transition:0.5s;
  background: #FFFFFF;
  //box-shadow: 0px 6px 10px 0px rgba(3, 8, 12, 0.15);
  .headBox{
    .logo{
      width: 130px;
      height: 32px;
      content: url("http://www.cd-rst.top:18000/static/header/rstlogo1.svg");
      cursor: pointer;
    }
    .logo_1{
      content: url("http://www.cd-rst.top:18000/static/header/rstlogo_2.svg");
    }
    .menuList{
        li>a{
          font-family: Medium;
          color: #999999;
          //必须需要相对定位，才能让css动画在此标签下
          position: relative;
        }
        //鼠标经过触发css动画
        li>a:hover:before {
          transform: scale(1);
        }
        //鼠标经过触发css动画
        li>a:before {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          height: 4px;
          width: 100%;
          background: #0564AF;
          transform: scale(0);
          transition: all .5s;
        }
      }
  }
}
//APP端适配列表
.mobileHeader{
  display: none;
}

//媒体查询适应手机端
@media screen and (min-width: 360px) and (max-width: 750px){
  html{
    font-size: 130px / 10;
  }
  .Header{
    display: none;
  }
  .mobileHeader{
    display: block;
    width: 100%;
    height: 4rem;
    position: fixed;
    left: 0px;
    top: 0px;
    //display: flex;
    //justify-content: center;
    //align-items: center;
    background: #FFFFFF;
    z-index: 1000;
    div{
      display: flex;
      justify-content: space-between;
      margin: 1.3rem auto;
      width: 88.88%;
      height: 1.384rem;
      .leftImg{
        width: 5.538rem;
        height: 1.384rem;
      }
      .rightImg{
        width: 1.46rem;
        height: 1.23rem;
      }
    }
    .mobileHeader_Box{
      width: 100%;
      height: 56.923rem;
      background-color: #fff;
      z-index: 1000;
      div{
        display: flex;
        flex-direction: column;
        justify-content: left;
        //width: 20rem;
        width: 96%;
        //height: 33.46rem;
        //height: 50%;
        margin: 0 auto;
        .mobileHeader_list{
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          //height: 4.538rem;
          border-bottom: 1px solid rgba(216, 216, 216, 0.5);
          p{
            font-family: Medium;
            //font-size: 1.15rem;
            font-size: 1rem;
            font-weight: 500;
            margin-left: 0.769rem;
            color: #454545;
          }
          img{
            margin-right: 0.769rem;
          }
        }
      }
      div/deep/.el-collapse-item {
        border-bottom: 1px solid rgba(216, 216, 216, 0.5) !important;
        height: 408px;
      }
      div/deep/.el-collapse-item__header .el-icon-arrow-right:before {
        content: url("http://www.cd-rst.top:18000/static//imges/header/add.svg");
      }
      .HomeContent/deep/.el-collapse-item__header .el-icon-arrow-right:before {
        content: "\e6e0";
      }
      .NewsContent/deep/.el-collapse-item__header .el-icon-arrow-right:before {
        content: "\e6e0";
      }
      div/deep/.el-collapse-item__arrow.is-active {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
      }
      div/deep/.el-collapse-item__header {
        //width: 18.462rem;
        width: 100%;
        height: 4.538rem;
        line-height: 4.538rem;
        color: #454545;
        padding:0 0.7rem;
        font-family: Medium;
        font-weight: 500;
        font-size: 1rem;
        border: 0;
      }
      div/deep/.el-collapse-item__content {
        text-align: left;
        //height: 11.1rem;
        border-top: 1px solid rgba(216, 216, 216, 0.5);
        padding-bottom: 0;
        a,div{
          display: block;
          margin-left: 1.3rem;
          height: 4.7rem;
          line-height: 4.8rem;
          font-family: Medium;
          font-size: 0.9rem;
          font-weight: 500;
          letter-spacing: 0em;
          color: #666666;
        }
      }
    }
  }
}
</style>

<style lang="less">
  li{
    list-style: none;
  }
</style>
