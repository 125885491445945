<template>
  <div id="about">
    <Header now-nav="/about" ref="header" @goAnchor="goAnchor" :status="status"></Header>
<!--    <div class="headerBox">-->
<!--      <img :src="baseUrl+'imges/about/aboutVideo.png'" alt="" class="myVideo">-->
<!--      <img :src="baseUrl+'imges/about/MobileAboutBG1.png'" alt="" class="MobileBG">-->
<!--      <div class="startVideo" @click="startPlay(0)">-->
<!--        <img :src="baseUrl+'imges/about/start.png'" alt="">-->
<!--        <p>点击观看视频</p>-->
<!--      </div>-->
<!--      <div v-if="playVideoId === 1" class="video-layer">-->
<!--        <div class="container">-->
<!--          <p @click="playVideoId=0">×</p>-->
<!--          <div>-->
<!--            <video controls autoplay poster="http://www.cd-rst.top:18000/static/imges/about/aboutVideo.png">-->
<!--              <source :src="baseUrl+'10.20RGM轨道巡检机器人.mp4'">-->
<!--            </video>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

    <!--  内容区  -->
    <div class="contentBoxAbout" id="anchor_1">
      <div class="nav">
        <img :src="baseUrl+'imges/about/aboutNavBG.png'" alt="" class="AboutBG2">
        <img :src="baseUrl+'imges/about/MobileAboutBG2.png'" alt="" class="MobileBG">

        <div class="navText" data-aos="fade-up" data-aos-duration="1000" >
          <p class="navTitle">成都蓉视通科技有限公司</p>
          <p class="navEnglish">Chengdu Rongshitong Technology Co.,Ltd.</p>
          <p class="navTxt Mobile">成都蓉视通科技有限公司（简称“蓉视通科技”）是一家专注于工业物联网的国家高新技术企业。
            公司成立于2016年，主营业务包括智慧管廊综合管理平台（化工/化工园区、长输管线、燃气管网、城市地下综合管网等），
            智能轨道巡检机器人及管理平台、工业通讯传输终端（4G/5G工业路由器、DTU/RTU、边缘计算网关等）。</p>
          <p class="navTxt PC">成都蓉视通科技有限公司（简称“蓉视通科技”）是一家专注于工业互联网领域的国家高新技术企业，公司成立于2016年，注册资金5000万元。公司以安全管控和智能管理业务为核心，致力于提升工业尤其是化工行业的数字化与智能化水平。
            公司主营业务有：智慧管廊综合管理平台（包括化工厂/化工园区管廊管理、燃气管网管理、城市地下管网管理）；智能轨道式巡检机器人及机器人管理平台；工业通讯传输终端（包括4G/5G工业路由器、边缘计算网关等）。
            <br><br>蓉视通科技秉承持续为用户创造价值信念，将一如既往地在工业互联网领域持续创新，为客户提供切实满足需求的产品及综合解决方案。</p>
          <div class="navTurn"  data-aos="fade-up" data-aos-duration="1000" >
            <router-link :to="{path:'/certificate'}">荣誉资质</router-link>
            <router-link :to="{path:'/honorvideo'}">公司视频</router-link>
          </div>
        </div>
      </div>
    </div>

    <!--  公司简介  -->
    <div class="companyBox" id="anchor_2">
      <img :src="baseUrl+'imges/about/aboutNextBG.png'" alt="" class="PC">
      <img :src="baseUrl+'imges/about/MobileAboutBG3.png'" alt="" class="MobileBG">
      <div class="companyList">
        <div class="divHover">
          <div>
            <h4>愿景</h4>
            <p>蓉视通科技愿为提升工业企业数字化和智能化水平不断创新，为客户创造更大的价值。</p>
          </div>
        </div>
        <div class="divHover">
          <div>
            <h4>使命</h4>
            <p>提升工业行业的数字化和智能化水平</p>
          </div>
        </div>
        <div class="divHover">
          <div>
            <h4>价值观</h4>
            <p>成就客户 诚实守信 开拓创新 合作共赢<br> 团结奋进</p>
          </div>
        </div>
      </div>
    </div>

    <!--  联系我们  -->
    <div class="relation" id="anchor_3">
      <img :src="baseUrl+'imges/about/aboutDownBG.png'" alt="" class="PC">
      <img :src="baseUrl+'imges/about/MobileAboutBG4.png'" alt="" class="MobileBG">
      <p class="relationUs">联系我们</p>
      <div class="relationBox">
        <div class="relationBox_Us">
          <div class="divTop">
            <p class="relationBox_Title">成都蓉视通科技有限公司</p>
            <p>地址：成都市高新区环球中心W6区</p>
<!--            <p>传真：028-84175896</p>-->
            <p>电话：028-84175896</p>
            <p>邮箱：rst@cd-rst.com</p>
<!--            暂时隐藏-->
<!--            <a href="http://wpa.qq.com/msgrd?v=3&amp;uin=3465582132&amp;site=qq&amp;menu=yes">-->
<!--              <span>在线咨询</span>-->
<!--            </a>-->

          </div>
        </div>
        <div class="mapBox">
          <Map></Map>
        </div>
<!--        <div class="relationBox_Us relationBox_Us_Mobile">-->
<!--          <div>-->
<!--            <p class="relationBox_Title">华南地区</p>-->
<!--            <p>电话：13882550741</p>-->
<!--            <p>邮箱：tanchuncai@cd-rst.com</p>-->
<!--            <p class="relationBox_Title">华东地区</p>-->
<!--            <p>电话：13693402061</p>-->
<!--            <p>邮箱：qingwei@cd-rst.com</p>-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import Map  from "@/components/Map"


export default {
  name: "About",
  data() {
    return {
      scollTop: 0,
      anchorTop_1: 0,
      anchorTop_2: 0,
      anchorTop_3: 0,
      status: 0,
      playVideoId: 0, //控制视频播放
    }
  },
  components: {
    Header,
    Footer,
    Map,
  },
  mounted() {
    //监听滚动高度
    window.addEventListener("scroll", this.handleScroll, true);
    this.anchorTop_1 = document.getElementById('anchor_1').offsetTop
    this.anchorTop_2 = document.getElementById('anchor_2').offsetTop
    this.anchorTop_3 = document.getElementById('anchor_3').offsetTop
  },
  methods: {
    //锚点跳转
    goAnchor(selector) {/*参数selector是id选择器（#anchor14）*/
      document.querySelector(selector).scrollIntoView({
        behavior: "smooth"
      })
      if(selector === '#anchor_1'){
        this.status = 1
      } else if(selector === '#anchor_2'){
        this.status = 2
      } else {
        this.status = 3
      }
    },
    //播放视频
    startPlay(e){
      this.playVideoId = e
    },
    handleScroll(e) {
      this.scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;
      if(this.scrollTop+10>=this.anchorTop_1 && this.scrollTop<this.anchorTop_2){
        this.status = 1
      }
      if(this.scrollTop+10>=this.anchorTop_2 && this.scrollTop<this.anchorTop_3){
        this.status = 2
      }
      if(this.scrollTop+10>=this.anchorTop_3){
        this.status = 3
      }
    },
  }
}
</script>

<style lang="less" scoped>
#about {
  overflow: hidden;
  position: relative;
  background: #FFFFFF;
  width: 100%;
  min-width: 1250px;
  .MobileBG{
    display: none;
  }
  //第一背景区
  .headerBox{
    height: 937px;
    .myVideo{
      display: block;
      width: 100%;
      height: 100%;
    }
    .startVideo{
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 400px;
      cursor: pointer;
      img{
        width: 88px;
        height: 88px;
        margin-bottom: 10px;
      }
      p{
        width: 329px;
        height: 100px;
        font-family: Medium;
        font-size: 16px;
        font-weight: 500;
        line-height: 17px;
        letter-spacing: 0em;
        color: #FFFFFF;
      }
    }
    //遮罩层展示Video
    .video-layer{
      position: fixed;
      z-index: 100;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: rgba(255, 255, 255, 0.7);
      .container{
        position: relative;
        width: 1250px;
        height: 704px;
        top: 100px;
        left: 50%;
        transform: translateX(-50%);
        p{
          position: absolute;
          top: 0;
          right: 15px;
          font-size: 40px;
          color: #FFFFFF;
          font-family: Regular;
          cursor: pointer;
          z-index: 1;
        }
        div{
          video{
            width: 100%;
          }
        }
      }
    }
  }
  //第二背景区
  .contentBoxAbout{
    .nav{
      position: relative;
      height: 900px;
      .AboutBG2{
        display: block;
        width: 100%;
        height: 900px;
      }
      .navText{
        position: absolute;
        top: 189px;
        left: 50%;
        transform: translateX(-50%);
        width: 866px;
        height: 300px;
        color: #FFFFFF;
        .navTitle{
          font-family: Regular;
          font-size: 30px;
          letter-spacing: 0.05em;
        }
        .navEnglish{
          font-family: Regular;
          font-size: 16px;
          margin: 7px 0 19px;
        }
        .PC{
          display: block;
        }
        .Mobile{
          display: none;
        }
        .navTxt{
          height: 100%;
          font-family: Medium;
          font-size: 14px;
          font-weight: 500;
          line-height: 28px;
          text-align: left;
        }
        .navTurn{
          display: flex;
          justify-content: space-between;
          width: 400px;
          height: 42px;
          margin: 40px auto;
          a{
            --bg-color: #0564AF;
            --font-color: rgba(1,1,1,0);

            width: 181px;
            height: 42px;
            box-sizing: border-box;
            border: 1px solid #F2F3FE;
            font-family: Regular;
            font-size: 14px;
            text-align: center;
            line-height: 42px;
            letter-spacing: 0.05em;
            color: #F2F3FE;

            transition: all 200ms ease-out;
            /* 以下为核心代码 */
            background-size: 200% 100%;
            background-position: right bottom;
            background-image: linear-gradient(to left, var(--font-color) 50%, var(--bg-color) 50%);
            //鼠标经过按钮
            &:hover {
              cursor: pointer;
              background-position: left bottom;
              color: #FFFFFF;
            }
          }
        }
      }
    }
  }
  //第三背景区
  .companyBox{
    position: relative;
    width: 100%;
    height: 900px;
    .PC{
      display: block;
      width: 100%;
      height: 100%;
    }
    .companyList{
      position: absolute;
      top: 292px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      width: 1600px;
      height: 242px;
      .divHover{
        width: 508px;
        height: 191px;
        div{
          position: relative;
          width: 100%;
          height: 100%;
          border-radius: 10px;
          background: rgba(0, 0, 0, 0.3);
          font-family: Medium;
          font-weight: 500;
          letter-spacing: 0.05em;
          color: #F2F3FE;
          h4{
            position: absolute;
            top: 50px;
            left: 50%;
            transform: translateX(-50%);
            font-size: 30px;
          }
          p{
            position: absolute;
            width: 412px;
            left: 50%;
            transform: translateX(-50%);
            top: 111px;
            font-size: 16px;
          }
        }

      }
      .divHover:hover{
        >div{
          transform: translateY(-51px);
          transition: all 1s;
          h4{
            width: 150px;
            background: url("http://www.cd-rst.top:18000/static/imges/about/companyLogo.png") no-repeat 10px 10px,
            url("http://www.cd-rst.top:18000/static/imges/about/companyLogo.png") no-repeat 120px 10px;
          }
        }
      }
      //div:hover{
      //  //align-self: flex-start;
      //  margin-bottom: 51px;
      //  transition: all 1s;
      //  h4{
      //    width: 150px;
      //    background: url("http://www.cd-rst.top:18000/static/imges/about/companyLogo.png") no-repeat 10px 10px,
      //    url("http://www.cd-rst.top:18000/static/imges/about/companyLogo.png") no-repeat 120px 10px;
      //  }
      //}
    }

  }

  //第四背景区
  .relation{
    position: relative;
    width: 100%;
    height: 821px;
    .PC{
      display: block;
      width: 100%;
      height: 100%;
    }
    .relationUs{
      display: block;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 120px;
      font-family: Regular;
      font-size: 30px;
      letter-spacing: 0.05em;
      color: #F2F3FE;
    }
    .relationUs:after{
      /* 必须设置 content 属性才能生效 */
      content: '';
      /* border 宽度 */
      width: 60px;
      /* border 高度 */
      height: 4px;
      background-color: #0564AF;
      /* border 位置 absolute(绝对定位) */
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 50px;
      /* 自动内减 */
      box-sizing: border-box;
    }
    .relationBox{
      display: flex;
      justify-content: space-between;
      position: absolute;
      top: 238px;
      left: 50%;
      transform: translateX(-50%);
      width: 1012px;
      height: 420px;
      .relationBox_Us{
        position: relative;
        width: 454px;
        height: 350px;
        border-radius: 12px;
        background: rgba(0, 0, 0, 0.3);
        .divTop{
          margin-top: 60px;
          p{
            margin-bottom: 42px !important;
          }
        }
        div{
          width: 356px;
          margin-left: 69px;
          text-align: left;
          font-family: Regular;
          .relationBox_Title{
            margin: 42px 0 35px;
            font-size: 20px;
            color: #FFFFFF;
          }
          p{
            margin-bottom: 30px;
            font-size: 16px;
            color: #F2F3FE;
          }
          a{
            --bg-color: #0564AF;
            --font-color: rgba(1,1,1,0);

            display: block;
            margin-top: 40px;
            width: 179px;
            height: 42px;
            border: 1px solid #F2F3FE;
            transition: all 200ms ease-out;
            /* 以下为核心代码 */
            background-size: 200% 100%;
            background-position: right bottom;
            background-image: linear-gradient(to left, var(--font-color) 50%, var(--bg-color) 50%);
            //鼠标经过按钮
            &:hover {
              cursor: pointer;
              background-position: left bottom;
            }
            span{
              display: block;
              width: 179px;
              height: 42px;
              font-size: 14px;
              letter-spacing: 0.05em;
              color: #F2F3FE;
              line-height: 42px;
              text-align: center;
            }
          }
        }
      }
      .mapBox {
        width: 454px;
        height: 350px;
        background: #94dd8f;
      }
    }
  }
}

//媒体查询适应手机端
@media screen and (min-width: 360px) and (max-width: 750px) {
  html {
    font-size: 140px / 10;
  }
  #about{
    background: #FFFFFF;
    width: 100%;
    height: 100%;
    .headerBox{
      width: 100%;
      height: 589px;
      margin-top: 50px;
      .myVideo{
        display: none;
      }
      .MobileBG{
        display: block;
        width: 100%;
        height: 100%;
      }
      .startVideo{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 257px;
        img{
          width: 60px;
          height: 60px;
        }
        p{
          width: 329px;
          height: 10px;
          font-family: Medium;
          font-size: 16px;
          font-weight: 500;
          line-height: 17px;
          letter-spacing: 0em;
          color: #FFFFFF;
        }
      }
      //遮罩层展示Video
      .video-layer{
        position: fixed;
        z-index: 100;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 1);
        .container{
          position: relative;
          width: 100%;
          top: 100px;
          left: 50%;
          transform: translateX(-50%);
          p{
            position: absolute;
            top: 0;
            right: 15px;
            font-size: 40px;
            color: #FFFFFF;
            font-family: Regular;
            cursor: pointer;
            z-index: 1;
          }
          div{
            video{
              width: 100%;
            }
          }
        }
      }
    }
    //第二背景区
    .contentBoxAbout{
      width: 100%;
      height: 100%;
      .nav{
        width: 100%;
        height: 100%;
        .AboutBG2{
          display: none;
        }
        .MobileBG{
          display: block;
          width: 100%;
          height: 658px;
        }
        .navText{
          width: 91%;
          height: 245px;
          margin: 70px auto;
          color: #FFFFFF;
          .navTitle{
            font-family: Regular;
            font-size: 20px;
            letter-spacing: 0.05em;
          }
          .navEnglish{
            font-family: Regular;
            font-size: 12px;
            margin: 7px 0 19px;
          }
          .PC{
            display: none;
          }
          .Mobile{
            display: block;
          }
          .navTxt{
            text-align: left;
            font-family: Medium;
            width: 100%;
            height: 100%;
            font-size: 12px;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: 0em;
            color: #FFFFFF;
          }
          .navTurn{
            width: 181px;
            height: 112px;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            a{
              display: block;
              width: 181px;
              height: 42px;
              background: #0564AF;
              border: 0;

              line-height: 42px;
              font-family: Regular;
              font-size: 14px;
              font-weight: normal;
              letter-spacing: 0.05em;
              color: #F2F3FE;
            }
          }
        }
      }
    }
    //第三背景区
    .companyBox{
      width: 100%;
      height: 633px;
      .PC{
        display: none;
      }
      .MobileBG{
        display: block;
        width: 100%;
        height: 100%;
      }
      .companyList{
        width: 92%;
        height: 474px;
        margin: 10px auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        .divHover{
          //阻止hover
          pointer-events: none;
          width: 317px;
          height: 138px;
          div{
            position: relative;
            width: 100%;
            height: 100%;
            border-radius: 8px;
            background: rgba(0, 0, 0, 0.3);

            font-family: Medium;
            font-weight: 500;
            letter-spacing: 0.05em;
            color: #F2F3FE;
            h4{
              position: absolute;
              top: 20px;
              left: 50%;
              transform: translateX(-50%);
              width: 100%;
              height: 38px;
              font-size: 26px;
            }
            p{
              position: absolute;
              top: 67px;
              left: 50%;
              transform: translateX(-50%);
              width: 90%;
              height: 20px;
              font-size: 14px;
            }
          }
        }
      }
    }
    //第四背景区
    .relation{
      position: relative;
      width: 100%;
      height: 775px;
      .PC{
        display: none;
      }
      .MobileBG{
        display: block;
        width: 100%;
        height: 100%;
      }
      .relationUs{
        display: none;
      }
      .relationBox{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 92%;
        height: 610px;
        .relationBox_Us{
          width: 300px;
          height: 230px;
          border-radius: 12px;
          background: rgba(0, 0, 0, 0.3);
          .divTop{
            margin-top: 30px;
          }
          div{
            width: 240px;
            height: 190px;
            text-align: left;
            font-family: Regular;
            margin: 47px 0 0 32px;
            .relationBox_Title{
              height: 40px;
              font-size: 16px;
              font-weight: normal;
              line-height: 24px;
              letter-spacing: 0em;
              color: #FFFFFF;
            }
            p{
              font-size: 14px;
              font-weight: normal;
              line-height: 30px;
              letter-spacing: 0em;
              color: #F2F3FE;
            }
            a{
              display: block;
              width: 179px;
              height: 42px;
              margin: 28px auto 0;
              box-sizing: border-box;
              border: 1px solid #F2F3FE;
              span{
                display: block;
                width: 100%;
                height: 100%;
                font-size: 14px;
                letter-spacing: 0.05em;
                color: #F2F3FE;
                line-height: 42px;
                text-align: center;
              }
            }
          }
        }
        .mapBox {
          width: 300px;
          height: 300px;
          background: #94dd8f;
        }
        .relationBox_Us_Mobile{
          width: 300px;
          height: 300px;
          div{
            width: 240px;
            height: 190px;
            text-align: left;
            font-family: Regular;
            margin: 25px auto 0;
            .relationBox_Title{
              margin: 20px auto 5px;
            }
            p{
              width: 100%;
              margin-bottom: 5px;
              font-size: 14px;
              font-weight: normal;
              line-height: 26px;
              letter-spacing: 0em;
              color: #F2F3FE;
              z-index: 1
            }
          }
        }
      }
    }
  }
}
</style>
