import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        ToScrollIntoView: 0,  //判断锚点定位是否触发和触发位置

    },
    mutations: {
        chengeScrollIntoView(state, num){
            state.ToScrollIntoView = num
        },
    }

})


