<template>
  <div id="home">
    <full-page ref="fullpage"
               :options="options"
               id="fullpage"
               resize=true
               autoScrolling="false"
    >
<!--   第1屏   -->
      <div class="section fp-auto-height firstPage">
        <Header now-nav="/home"></Header>
        <!-- 内容区 -->
        <div class="mainContent">
          <div class="img-js">
            <p>智慧管廊、智慧园区、智能巡检</p>
            <h4 class="PC">工业物联网软硬件制造商</h4>
            <h4 class="Mobile">工业物联网<br>软硬件制造商</h4>
            <router-link :to="{path: '/about'}">
              <div class="knowMore">
                <span>了解更多</span>
              </div>
            </router-link>
            <video id="myVideo"
                   data-keepplaying
                   oncontextmenu="return false"
                   style="width: 99vw;height: 100vh;object-fit: fill;"
                   playsinline="true"
                   webkit-playsinline="true"
                   x5-video-player-type="h5"
                   x5-video-player-fullscreen="true"
            >
              <source :src="baseUrl+'homeVideo3.mp4'" type="video/mp4">
              <track kind="captions">
            </video>
            <video id="myVideo_Mobile"
                   muted
                   autoplay
                   loop
                   preload="auto"
                   data-keepplaying
                   oncontextmenu="return false"
                   style="width: 99vw;height: 100vh;object-fit: fill;"
                   playsinline="true"
                   webkit-playsinline="true"
                   x5-video-player-type="h5"
                   x5-video-player-fullscreen="true"
            >
              <source :src="baseUrl+'imges/home/homeVideo_Mobile.mp4'" type="video/mp4">
              <track kind="captions">
            </video>
          </div>
        </div>
      </div>
<!--   第2屏   -->
      <div class="section fullBg">
        <div class="rstspecialist"  data-aos="fade-up" data-aos-duration="1000">
          <span class="twoTitle" data-aos="fade-up" data-aos-duration="1000">行业领先的智慧管廊建设体系</span>
          <video autoplay loop muted data-keepplaying class="TwoBG">
            <source :src="baseUrl+'home/dynamicVideo.mp4'">
          </video>
          <img :src="baseUrl+'imges/home/dynamicMobile.png'" alt="" class="TwoBGMobile">
        </div>
      </div>
<!--   第3屏   -->
      <div class="section fullBg">
        <!--  解决方案  -->
        <div class="threeScreen">
          <span class="threeTitle" data-aos="fade-up" data-aos-duration="1000" >解决方案</span>
          <div class="solutionlist">
            <div class="solutionBox"
                 data-aos="fade-up"
                 data-aos-duration="500"
                 @click="$router.push({name: 'Solutionlist', params: {type:'wisdomChemical',name:'智慧化工管廊'}})"
            >
              <router-link :to="{name: 'Solutionlist', params: {type:'wisdomChemical',name:'智慧化工管廊'}}" class="topImg">
                <img :src="baseUrl+'home/topImg1.png'" alt="">

              </router-link>
              <div class="solutionText">
                <p class="centerText">
                  <img :src="baseUrl+'home/centerImg1.png'" alt="">

                  <span class="centerTitle">智慧化工管廊</span>
                  <span class="centerEnglish">Smart chemical pipe gallery</span>
                </p>
                <div class="downText">
                  <span>智慧化工管廊管理系统是专门针对化工园区的公共管廊，在遵循石化行业特点和特定需求的基础上，利用先进的信息...</span>
                  <ul>
                    <li><p>管廊、管线全流程管理</p></li>
                    <li><p>应急管理数字化</p></li>
                    <li><p>异构设备联动一体化</p></li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="solutionBox middleBox"
                 data-aos="fade-up"
                 data-aos-duration="1000"
                 @click="$router.push({name: 'Solutionlist', params: {type:'wisdomGas',name:'智慧燃气管网'}})"
            >
              <router-link :to="{name: 'Solutionlist', params: {type:'wisdomGas',name:'智慧燃气管网'}}" class="topImg">
                <img :src="baseUrl+'home/topImg2.png'" alt="">

              </router-link>
              <div class="solutionText">
                <p class="centerText">
                  <img :src="baseUrl+'home/centerImg2.png'" alt="">
                  <span class="centerTitle">智慧燃气管网</span>
                  <span class="centerEnglish">Smart gas pipeline network</span>
                </p>
                <div class="downText">
                  <span>智慧燃气系统是专门针对燃气行业输配关系，在实现燃气管线的集中管理，动态维护和应用服务基础上，做到管线信息...</span>
                  <ul>
                    <li><p>燃气管网压力、流量实时监测</p></li>
                    <li><p>燃气输配管理“一张网”</p></li>
                    <li><p>地下综合管网信息交换，共建共享</p></li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="solutionBox"
                 data-aos="fade-up" data-aos-duration="1500"
                 @click="$router.push({name: 'Solutionlist', params: {type:'wisdomCity',name:'智慧城市地下管网'}})"
            >
              <router-link :to="{name: 'Solutionlist', params: {type:'wisdomCity',name:'智慧城市地下管网'}}" class="topImg">
                <img :src="baseUrl+'home/topImg3.png'" alt="">
              </router-link>
              <div class="solutionText">
                <div class="centerText">
                  <img :src="baseUrl+'home/centerImg3.png'" alt="">
                  <span class="centerTitle">智慧地下管网</span>
                  <span class="centerEnglish">Intelligent pipe network</span>
                </div>
                <div class="downText">
                  <span>地下管网系统可实现城市地下所有管线集中管理、动态维护和应用服务。系统结合二三维一体化、三维可视化、大数据...</span>
                  <ul>
                    <li><p>城市内涝预警，危害预测</p></li>
                    <li><p>供水管网泄漏监测，应急响应</p></li>
                    <li><p>地下管网施工模拟，管网分析</p></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
<!--   第4屏   -->
      <div class="section fullBg">
        <!--  新闻动态  -->
        <div class="fourScreen" data-aos="fade-up" data-aos-duration="1000" >
<!--          新闻标题-->
          <span class="fourTitle">新闻动态</span>
<!--          新闻盒子-->
            <div class="newslist">
              <div class="newsLeft" @click="$router.push({name: 'Newslist', params: {type:'news02',name:'蓉视通科技：巡检机器人、工业5G路由器、DTU亮相IOTE国际物联网展'}})">
                <div class="newsLeftImg">
                  <img :src="baseUrl+'home/newsLeftImg.png'" alt="">
                </div>
                <p class="newsTime">2021-04-30</p>
                <p class="newsTitle">蓉视通科技：巡检机器人、工业5G路由器、DTU亮相IOTE国际物联网展</p>
<!--                <p class="newsText">2022年6月9日，成都市名优产品供需企业联盟秘书处领导杨梅一行走访蓉视通科技考察交流，事业部总经理李亮亮等相关人员陪同接待，双方就蓉视通的技术产品及应用领域进行了深度的探讨和交流。</p>-->
                <p class="newsText">第十五届国际IOTE物联网展于2021年4月21日至23日在上海世博展览馆举行。展会上汇集了全球700+家展商，针对物联网全产业链进行了完整的展示，涵盖物联网感知层、网络传输层、运算与平台…</p>
              </div>
              <div class="newsRight">
                <div>
                  <p class="newTime">2021-03-23</p>
                  <router-link :to="{name: 'Newslist', params: {type:'news03',name:'蓉视通科技荣获成都2020年度5G创新应用优秀项目奖'}}">
                    蓉视通科技荣获成都2020年度5G创新应用优秀项目奖
                  </router-link>
                </div>
                <div>
                  <p class="newTime">2019-04-11</p>
                  <router-link :to="{name: 'Newslist', params: {type:'news01',name:'公共管廊新国标:园区物料“动脉”数字化建设将提速'}}">
                    公共管廊新国标：园区物流“动脉”数字化将提速
                  </router-link>
                </div>
                <div>
                  <p class="newTime">2021-02-20</p>
                  <router-link :to="{name: 'Newslist', params: {type:'news03',name:'“工业互联网+安全生产”行动计划（2021-2023年）'}}">
                    “工业互联网+安全生产”行动计划（2021-2023年）
                  </router-link>
                </div>
                <div class="displayNewsList">
                  <p class="neTime">2021-01-19</p>
                  <router-link :to="{name: 'Newslist', params: {type:'news01',name:'打造一体高效综合枢纽 陕西推进智慧绿色交通发展'}}">
                    打造一体高效综合枢纽 陕西推进智慧绿色交通发展
                  </router-link>
                </div>
              </div>
            </div>
<!--          了解更多-->
          <div class="knowMore" @click="$router.push('/news')">
            <span>了解更多</span>
          </div>
        </div>
        <Footer class="PCFooter" style="position: absolute; bottom: 0; width: 100%;"></Footer>
        <Footer class="MobileFooter"></Footer>
      </div>
    </full-page>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import About from "./About";

export default {
  name: 'Home',
  data(){
    return{
      solutionRightState:true,
      tabBoxSolution:true,//解决方案
      tabBoxProduct:false,//产品
      tabBoxCompany:true, //公司动态
      tabBoxIndustry:false, //行业资讯
      options: {
        // menu: '#menu',
        // anchors: ['工业物联网专业供应商','工业物联网专家', '解决方案&硬件产品', '新闻动态'],
        // sectionsColor: ['#41b883', '#ff5f45', '#DFDDDD'],
        carouselHeight: "62rem",
        //autoScrolling:false,
        scrollBar:true,
        autoScrolling: true,
      },
      windowWidth: 0, //获取屏幕宽度
    }
  },
  components: {
    Header,
    Footer,
    About,
  },
  created() {
  },
  mounted() {
    this.initVideo();
    this.windowWidth = document.body.clientWidth
    window.onresize = () => {
      return (() => {
        this.windowWidth = document.body.clientWidth
      })()
    }

      this.startVideo()

  },
  watch: {
    windowWidth: {
      handler: function (val, oldVal) {
        if (val < 750) {
          this.$refs.fullpage.destroy();
        } else {
          this.$refs.fullpage.init();
        }
      },
    },
  },
  methods: {
    //视频初始化
    initVideo() {
      //初始化视频方法
      let myPlayer = this.$video(myVideo, {
        //确定播放器是否具有用户可以与之交互的控件。没有控件，启动视频播放的唯一方法是使用autoplay属性或通过Player API。
        controls: false,
        //自动播放属性,muted:静音播放
        autoplay: true,
        //建议浏览器是否应在<video>加载元素后立即开始下载视频数据。
        preload: "auto",
        // //设置视频播放器的显示宽度（以像素为单位）
        // width: "1903px",
        // //设置视频播放器的显示高度（以像素为单位）
        // height: "778px",
        muted: true,
        //循环播放
        loop: true
      });
    },
    startVideo(){
        let voice = document.getElementById('myVideo');
        if(typeof WeixinJSBrdgeReady=="object" && typeof WeixinJSBridge.invoke == "function"){
          voice.play()
        }else{
          if (document.addEventListener) {
            document.addEventListener("WeixinJSBridgeReady", function () {
              voice.play();
            }, false);
          } else if (document.attachEvent) {
            document.attachEvent("WeixinJSBridgeReady", function () {
              voice.play();
            });
            document.attachEvent("onWeixinJSBridgeReady", function () {
              voice.play();
            });
          }
        }
    },
    getClass(){
      this.$refs.fullpage.api.moveSectionDown()
    },
  },

}
</script>
<style lang="less" scoped>
 #home {
   .PC{
     display: block;
   }
   .Mobile{
     display: none;
   }
   //#Footer{
   //  position: absolute;
   //  bottom: 0;
   //  width: 100%;
   //}
   background-color: #fff;
   width: 99vw;
   margin: 0;
   padding: 0;
   min-width: 1250px;
   //height: 937*4px;
   position: relative;
   .img-js{
     width: 99vw;
     height: 100vh;
     z-index: -1;
   }
 // 第一屏内容区
   .mainContent{
     height: 937px;
     position: relative;
     cursor: default;
     user-select: none;
     #myVideo{
       display: block;
     }
     #myVideo_Mobile{
       display: none;
     }
     h4{
       position: absolute;
       left: 50%;
       transform: translateX(-50%);
       //bottom: 487px;
       top: 372px;
       width: 702px;
       height: 78px;
       z-index: 2;
       font-family: Medium;
       font-size: 50px;
       font-weight: 500;
       letter-spacing: 0em;
       color: #FFFFFF;
     }
     p{
       position: absolute;
       left: 50%;
       transform: translateX(-50%);
       //bottom: 586px;
       top: 309px;
       width: 868px;
       height: 42px;
       font-size: 20px;
       font-family: Regular;
       font-weight: 500;
       color: #E3E3E3;
       z-index: 2;

     }
     .knowMore{
       --bg-color: #fff;
       --font-color: rgba(1,1,1,0);

       position: absolute;
       left: 50%;
       transform: translateX(-50%);
       //bottom: 403px;
       top: 491px;
       width: 157px;
       height: 41px;
       border: 1px solid #F2F3FE;
       z-index: 2;
       transition: all 200ms ease-out;
       /* 以下为核心代码 */
       background-size: 200% 100%;
       background-position: right bottom;
       background-image: linear-gradient(to left, var(--font-color) 50%, var(--bg-color) 50%);

       //鼠标经过按钮
       &:hover {
         cursor: pointer;
         background-position: left bottom;
         span{
           color: #000000;
         }
       }
       span {
         display: block;
         width: 89px;
         height: 22px;
         font-size: 16px;
         padding-top: 10px;
         padding-left: 35px;
         font-family: Regular;
         font-weight: 500;
         color: #F2F3FE;
         z-index: 2;

       }
     }
   }
 //  第二屏内容区
   .rstspecialist{
     height: 937px;
     position: relative;
     background: #f2f2fd;
     .twoTitle{
       position: absolute;
       left: 50%;
       transform: translateX(-50%);
       top: 123px;
       text-align: center;
       width: 590px;
       height: 42px;
       font-size: 30px;
       font-family: Regular;
       //font-weight: bold;
       color: #333333;
     }
     .twoTitle:after{
       /* 必须设置 content 属性才能生效 */
       content: '';
       /* border 宽度 */
       width: 60px;
       /* border 高度 */
       height: 4px;
       background-color: #0564AF;
       /* border 位置 absolute(绝对定位) */
       position: absolute;
       left: 50%;
       transform: translateX(-50%);
       top: 50px;
       /* 自动内减 */
       box-sizing: border-box;
     }
     .TwoBG{
       visibility: visible;
       width: 1293px;
       height: 690px;
       margin: 200px auto;
       z-index: -1;
     }
     .TwoBGMobile{
       visibility: hidden;
     }
   }

 //  第三屏
   //解决方案
   .threeScreen{
     height: 937px;
     min-width: 1600px;
     position: relative;
     .threeTitle{
       position: absolute;
       left: 50%;
       transform: translateX(-50%);
       top: 123px;
       text-align: center;
       width: 590px;
       height: 42px;
       font-size: 30px;
       font-family: Regular;
       //font-weight: bold;
       color: #333333;
     }
     .threeTitle:after{
       /* 必须设置 content 属性才能生效 */
       content: '';
       /* border 宽度 */
       width: 60px;
       /* border 高度 */
       height: 4px;
       background-color: #0564AF;
       /* border 位置 absolute(绝对定位) */
       position: absolute;
       left: 265px;
       top: 50px;
       /* 自动内减 */
       box-sizing: border-box;
     }
     .solutionlist{
       display: flex;
       justify-content: space-between;
       position: absolute;
       top: 220px;
       left: 50%;
       transform: translateX(-50%);
       //width: 1600px;
       height: 638px;
       .middleBox{
         margin: 0 30px;
       }
       .solutionBox{
         display: flex;
         flex-direction: column;
         justify-content: space-between;
         width: 513px;
         height: 638px;
         background-color: #f7fafc;
         &:hover{
           cursor: pointer;
         }
         .topImg{
           width: 513px;
           height: 256px;
           overflow: hidden;
           cursor: pointer;
           img{
             width: 100%;
             transition: all .6s;
           }
           img:hover{
             transform: scale(1.1);
           }
         }
         .solutionText{
           position: relative;
           width: 427px;
           height: 382px;
           margin: 0 auto;
           .centerText{
             height: 110px;
             border-bottom: 1px solid #D8D8D8;
             img{
               position: absolute;
               top: 49.38px;
               left: 0;
             }
             .centerTitle{
               position: absolute;
               left: 60px;
               top: 49.38px;
               width: 154px;
               height: 29px;
               font-family: Regular;
               font-size: 18px;
               font-weight: normal;
               text-align: left;
               letter-spacing: 0em;
               color: #3D3D3D;
             }
             .centerEnglish{
               position: absolute;
               left: 60px;
               top: 75px;
               height: 20px;
               font-family: Regular;
               font-size: 14px;
               font-weight: normal;
               text-transform: uppercase;
               letter-spacing: 0em;
               color: #999999;
             }
           }
           .downText{
             span{
               position: absolute;
               left: 0px;
               top: 126px;
               text-align: left;
               font-family: Regular;
               font-size: 14px;
               font-weight: normal;
               line-height: 31px;
               letter-spacing: 0em;
               color: #3D3D3D;
             }
             ul{
               position: absolute;
               left: 0;
               top: 216px;
               text-align: left;
               font-family: Regular;
               font-size: 14px;
               font-weight: normal;
               line-height: 37px;
               letter-spacing: 0em;
               color: #666666;
               li{
                 background: url("http://www.cd-rst.top:18000/static/home/point.png") left no-repeat;
                 p{
                   margin-left: 18px;
                 }
               }
             }
           }
         }
       }
     }
   }

 //  第四屏
 //  新闻
   //最外层父级盒子，子绝父相
   .fourScreen{
     height: 937px;
     min-width: 1600px;
     position: relative;
     //新闻动态标题
     .fourTitle{
       position: absolute;
       left: 50%;
       transform: translateX(-50%);
       top: 73px;
       text-align: center;
       width: 590px;
       height: 42px;
       font-size: 30px;
       font-family: Regular;
       //font-weight: bold;
       color: #333333;
     }
     //新闻动态标题下的横线
     .fourTitle:after{
       /* 必须设置 content 属性才能生效 */
       content: '';
       /* border 宽度 */
       width: 60px;
       /* border 高度 */
       height: 4px;
       background-color: #0564AF;
       /* border 位置 absolute(绝对定位) */
       position: absolute;
       left: 265px;
       top: 50px;
       /* 自动内减 */
       box-sizing: border-box;
     }
     //新闻咨询外层盒子，居中、flex模式
     .newslist{
       width: 1200px;
       height: 371px;
       display: flex;
       justify-content: space-between;
       position: absolute;
       top: 150px;
       left: 50%;
       transform: translateX(-50%);
       //左侧盒子
       .newsLeft{
         width: 565px;
         height: 371px;
         text-align: left;
         &:hover{
           cursor: pointer;
           .newsTitle{
             color: #0564AF !important;
           }
         }
         .newsLeftImg{
           width: 565px;
           height: 269px;
           overflow: hidden;
           cursor: pointer;
           img{
             width: 100%;
             height: 256px;
             transition: all .6s;
           }
           img:hover{
             transform: scale(1.1);
           }
         }
         .newsTime{
           font-size: 14px;
           letter-spacing: 0.05em;
           font-family: Regular;
           color: #999999;
         }
         .newsTitle{
           font-size: 16px;
           letter-spacing: 0em;
           font-family: Regular;
           color: #454545;
           margin: 8px 0;
         }
         .newsText{
           font-family: Regular;
           font-size: 12px;
           font-weight: normal;
           line-height: 20px;
           letter-spacing: 0em;
           color: #999999;
         }
       }
       .newsRight{
         width: 600px;
         height: 371px;
         border-top: 1px solid #E6E6E6;
         div:hover{
           cursor: pointer;
           border-bottom: 1px solid #0564AF;
           a{
             color: #0564AF !important;
           }
         }
         div{
           width: 600px;
           height: 91px;
           text-align: left;
           border-bottom: 1px solid #E6E6E6;
           p{
             height: 42px;
             line-height: 42px;
             font-family: Regular;
             font-size: 14px;
             font-weight: normal;
             letter-spacing: 0.05em;
             color: #999999;
           }
           a{
             font-family: Regular;
             font-size: 14px;
             font-weight: normal;
             letter-spacing: 0.05em;
             color: #454545;
           }
         }
       }
     }
     //了解更多按钮
     .knowMore{
       --bg-color: #0564AF;
       --font-color: #fff;

       width: 179px;
       height: 42px;
       line-height: 42px;
       position: absolute;
       top: 550px;
       left: 50%;
       transform: translateX(-50%);
       //padding: 8px 60px 14px 60px;
       box-sizing: border-box;
       border: 1px solid #0564AF;

       transition: all 200ms ease-out;
       /* 以下为核心代码 */
       background-size: 200% 100%;
       background-position: right bottom;
       background-image: linear-gradient(to left, var(--font-color) 50%, var(--bg-color) 50%);

       //鼠标经过按钮
       &:hover {
         cursor: pointer;
         background-position: left bottom;
         span{
           color: #FFFFFF;
         }
       }
       //了解更多字体样式
       span {
         font-family: Regular;
         font-size: 14px;
         color: #0564AF;
       }
     }
   }
   .PCFooter{
     display: block;
     position: absolute;
     bottom: 0;
     width: 100%;
   }
   .MobileFooter{
     display: none;
   }
 }

 //媒体查询适应手机端
 @media screen and (min-width: 360px) and (max-width: 750px) {
   html{
     font-size: 140px / 10;
   }
   #home {
     .PC{
       display: none;
     }
     .Mobile{
       display: block;
     }
     position: relative;
     width: 100vw;
     height: 3500px;
     margin: 0;
     padding: 0;
     background: #FFFFFF;
     //第一屏
     .mainContent{
       height: 100%;
       position: relative;
       cursor: default;
       user-select: none;
       #myVideo{
         display: none;
       }
       #myVideo_Mobile{
         display: block;
       }
       h4{
         position: absolute;
         left: 50%;
         transform: translateX(-50%);
         //bottom: 487px;
         top: 14.5rem;
         width: 14rem;
         height: 7.27rem;
         z-index: 2;
         font-family: Medium;
         font-size: 2.14rem;
         font-weight: 500;
         letter-spacing: 0em;
         color: #FFFFFF;
       }
       p{
         position: absolute;
         left: 50%;
         transform: translateX(-50%);
         //bottom: 586px;
         top: 12.71rem;
         width: 14rem;
         height: 1.21rem;
         font-size: 1rem;
         font-family: Regular;
         font-weight: 500;
         color: #E3E3E3;
         z-index: 2;
       }
       .knowMore{
         display: flex;
         justify-content: center;
         position: absolute;
         left: 50%;
         transform: translateX(-50%);
         //bottom: 403px;
         top: 31.64rem;
         width: 12.21rem;
         height: 2.78rem;
         border: 1px solid #F2F3FE;
         z-index: 2;
         span {
           display: block;
           width: 12.21rem;
           height: 2.78rem;
           line-height: 2.78rem;
           text-align: center;
           font-size: 1.14rem;
           font-family: Regular;
           font-weight: 500;
           color: #F2F3FE;
           z-index: 2;
           letter-spacing: 0.05em;
         }
       }

     }

     //第二屏
     .rstspecialist{
       position: relative;
       height: 399px;
       background-color: #fff;
       margin-bottom: 70px;
       .twoTitle{
         position: absolute;
         left: 50%;
         transform: translateX(-50%);
         top: 83px;
         width: 21rem;
         height: 2rem;
         font-size: 1.42rem;
         font-family: Regular;
         color: #333333;
       }
       .TwoBG{
         visibility: hidden;
       }
       .TwoBGMobile{
         visibility: visible;
         width: 100%;
         height: 53vw;
         margin: -60px auto;
       }
     }

     //第三屏
     .threeScreen{
       position: relative;
       height: 1640px;
       .threeTitle{
         position: absolute;
         left: 50%;
         transform: translateX(-50%);
         top: 0;
         font-family: Regular;
         color: #454545;
         font-size: 1.43rem;
         font-weight: normal;
         letter-spacing: 0.05em;
       }
       .threeTitle:after{
         /* 必须设置 content 属性才能生效 */
         content: '';
         /* border 宽度 */
         width: 31px;
         /* border 高度 */
         height: 2px;
         background-color: #0564AF;
         /* border 位置 absolute(绝对定位) */
         position: absolute;
         left: 50%;
         transform: translateX(-50%);
         top: 35px;
         /* 自动内减 */
         box-sizing: border-box;
       }
       .solutionlist{
         display: flex;
         flex-direction: column;
         justify-content: left;
         position: absolute;
         top: 74px;
         left: 50%;
         transform: translateX(-50%);
         //width: 1600px;
         //width: 320px;
         width: 90%;
         height: 487px;
         .middleBox{
           margin: 50px 0;
         }
         .solutionBox{
           display: flex;
           flex-direction: column;
           justify-content: space-between;
           width: 100%;
           height: 487px;
           background-color: #f7fafc;
           &:hover{
             cursor: pointer;
           }
           .topImg{
             width: 100%;
             height: 170px;
             overflow: hidden;
             cursor: pointer;
             img{
               width: 100%;
               transition: all .6s;
             }
             img:hover{
               transform: scale(1.1);
             }
           }
           .solutionText{
             position: relative;
             width: 319.06px;
             height: 317px;
             margin: 0 auto;

             .centerText{
               position: relative;
               width: 275px;
               height: 87px;
               margin: 0 auto;
               border-bottom: 1px solid #D8D8D8;
               img{
                 position: absolute;
                 top: 29.38px;
                 //left: 11.19px;
                 left: 0;
                 width: 35.63px;
                 height: 33.25px;
               }
               .centerTitle{
                 position: absolute;
                 //left: 62px;
                 left: 50px;
                 top: 20.5px;
                 width: 154px;
                 height: 29px;
                 font-family: Regular;
                 font-size: 18px;
                 font-weight: normal;
                 text-align: left;
                 letter-spacing: 0em;
                 color: #3D3D3D;
               }
               .centerEnglish{
                 position: absolute;
                 //left: 62px;
                 left: 50px;
                 top: 49.5px;
                 height: 20px;
                 font-family: Regular;
                 font-size: 12px;
                 font-weight: normal;
                 text-transform: uppercase;
                 letter-spacing: 0em;
                 color: #999999;
               }
             }
             .downText{
               position: relative;
               width: 281.52px;
               height: 192px;
               margin: 0 auto;
               font-family: Regular;
               font-weight: normal;
               letter-spacing: 0em;
               span{
                 position: absolute;
                 left: 0px;
                 top: 10px;
                 font-size: 13px;
                 text-align: left;
                 line-height: 31px;
                 letter-spacing: 0em;
                 color: #3D3D3D;
               }
               ul{
                 position: absolute;
                 left: 0;
                 top: 106px;
                 font-size: 14px;
                 text-align: left;
                 line-height: 37px;
                 letter-spacing: 0em;
                 color: #666666;
                 li{
                   background: url("http://www.cd-rst.top:18000/static/home/point.png") left no-repeat;
                   p{
                     margin-left: 18px;
                   }
                 }
               }
             }
           }
         }
       }

     }

     //第四屏
     .fourScreen{
       position: relative;
       //width: 320px;
       width: 90%;
       height: 873px;
       margin: 46px auto 0;
       //新闻动态标题
       .fourTitle{
         position: absolute;
         left: 50%;
         transform: translateX(-50%);
         top: 0;
         font-family: Regular;
         color: #454545;
         font-size: 1.43rem;
         font-weight: normal;
         letter-spacing: 0.05em;
       }
       //新闻动态标题下的横线
       .fourTitle:after{
         /* 必须设置 content 属性才能生效 */
         content: '';
         /* border 宽度 */
         width: 31px;
         /* border 高度 */
         height: 2px;
         background-color: #0564AF;
         /* border 位置 absolute(绝对定位) */
         position: absolute;
         left: 50%;
         transform: translateX(-50%);
         top: 35px;
         /* 自动内减 */
         box-sizing: border-box;
       }
       .newslist{
         //width: 320px;
         width: 92%;
         height: 596px;
         display: flex;
         flex-direction: column;
         justify-content: left;
         position: absolute;
         top: 61px;
         left: 50%;
         transform: translateX(-50%);
         //左侧盒子
         .newsLeft{
           width: 100%;
           height: 371px;
           text-align: left;
           &:hover{
             cursor: pointer;
             .newsTitle{
               color: #0564AF !important;
             }
           }
           .newsLeftImg{
             width: 100%;
             height: 187px;
             overflow: hidden;
             img{
               width: 100%;
               height: 170px;
             }
           }
           .newsTime{
             letter-spacing: 0.05em;
             font-family: Regular;
             font-size: 10px;
             font-weight: normal;
             letter-spacing: 0.05em;
             color: #454545;
           }
           .newsTitle{
             font-size: 16px;
             letter-spacing: 0em;
             font-family: Regular;
             color: #454545;
             margin: 8px 0;
             font-weight: normal;
             color: #454545;
           }
           .newsText{
             font-family: Regular;
             font-size: 12px;
             font-weight: normal;
             line-height: 20px;
             letter-spacing: 0em;
             color: #999999;
             margin-bottom: 22px;
           }
         }
         .newsRight{
           width: 100%;
           height: 371px;
           border-top: 1px solid #E6E6E6;
           div:hover{
             cursor: pointer;
             border-bottom: 1px solid #0564AF;
             a{
               color: #0564AF !important;
             }
           }
           .displayNewsList{
             display: none;
           }
           div{
             width: 100%;
             height: 91px;
             text-align: left;
             border-bottom: 1px solid #E6E6E6;
             p{
               height: 42px;
               line-height: 42px;
               font-family: Regular;
               font-size: 10px;
               font-weight: normal;
               letter-spacing: 0.05em;
               color: #454545;
             }
             a{
               font-family: Regular;
               font-size: 14px;
               font-weight: normal;
               letter-spacing: 0.05em;
               color: #454545;
             }
           }
         }
       }
       //了解更多按钮
       .knowMore{
         width: 171px;
         height: 39px;
         line-height: 39px;
         position: absolute;
         top: 755px;
         left: 50%;
         transform: translateX(-50%);
         border: 1px solid #0564AF;
         //了解更多字体样式
         span {
           font-family: Regular;
           font-size: 14px;
           color: #0564AF;
         }
       }

     }
     .PCFooter{
       display: none;
     }
     .MobileFooter{
       display: block;
     }
   }
 }
</style>

